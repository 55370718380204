import accountRecoveryConfirmTemplate from "./accountRecoveryConfirmTemplate";
import accountRecoveryTemplate from "./accountRecoveryTemplate";
import accountRecoveryMultipleUsrsTemplate from "./accountRecoveryMultipleUsrsTemplate";
import loginHelpActivationCodeEntryTemplate from "./loginHelpActivationCodeEntryTemplate";
import loginHelpDeliveryOptionsTemplate from "./loginHelpDeliveryOptionsTemplate";
import loginHelpResetPasswordDeliveryOptionsTemplate from "./loginHelpResetPasswordDeliveryOptionsTemplate";
import loginHelpTemplate from "./loginHelpTemplate";
import loginHelpOptionsTemplate from "./loginHelpOptionsTemplate";
import noPasswordTemplate from "./noPasswordTemplate";

const accountRecoveryRoute = function ($stateProvider) {
    $stateProvider
        .state("accountRecovery", {
            url: "/account-recovery",
            template: accountRecoveryTemplate,
            controller: "accountRecoveryController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("accountRecoveryMultipleUsrs", {
            url: "/account-recovery-multipleUsrs",
            template: accountRecoveryMultipleUsrsTemplate,
            controller: "accountRecoveryController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("accountRecoveryConfirm", {
            url: "/account-recovery-confirm",
            template: accountRecoveryConfirmTemplate,
            controller: "accountRecoveryController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        // login help =================================
        .state("loginHelpOptions", {
            url: "/loginHelpOptions?accu",
            template: loginHelpOptionsTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("loginHelp", {
            url: "/loginHelp?accu",
            template: loginHelpTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("noPasswordOptions", {
            url: "/loginHelpNoPassword",
            template: noPasswordTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("loginHelpResetPasswordDeliveryOptions", {
            url: "/resetPasswordActivationCodeDeliveryOptions",
            template: loginHelpResetPasswordDeliveryOptionsTemplate,
            controller: "mfaController",
            data: {
                fullHeight: true,
                title: "Forgot Password"
            }
        })
        .state("loginRecoveryHelp", {
            url: "/loginHelp?errorMessage=true&attempts=done",
            template: loginHelpTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Account Recovery"
            }
        })
        .state("loginHelpDeliveryOptions", {
            url: "/activationCodeDeliveryOptions",
            template: loginHelpDeliveryOptionsTemplate,
            controller: "mfaController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("verifyCodeForLoginHelp", {
            url: "/verifyCode",
            template: loginHelpActivationCodeEntryTemplate,
            controller: "mfaVerifyCodeController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        });
};

accountRecoveryRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default accountRecoveryRoute;
