import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import groupTxnAccess from "../../services/common/groupTxnAccess";

const useGroupTxnAccess = (
    groupId: string | undefined,
    individualId: string | undefined,
    txnCode: string
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GROUP_TXN_ACCESS, groupId, individualId, txnCode],
        queryFn: async () => {
            const { data } = await groupTxnAccess({ groupId, individualId, txnCode });
            return data;
        },
        enabled: Boolean(groupId && individualId && txnCode)
    });
};

export default useGroupTxnAccess;
