import React, { useState, useMemo } from "react";

const HelloWorld = () => {
    const [count, setCount] = useState(0);
    const [items] = useState(() => generateItems(300));

    const selectedItem = useMemo(() => items.find((item) => item.id === count), [count, items]);

    const updateStyleClass = {
        color: "white"
    };

    function generateItems(count) {
        const items = [];
        for (let i = 0; i < count; i++) {
            items.push({
                id: i,
                isSelected: i === count - 1
            });
        }
        return items;
    }

    return (
        <div>
            <h1 style={updateStyleClass}>Hello World</h1>
            <h1 style={updateStyleClass}>Count: {count}</h1>
            <h1 style={updateStyleClass}>Selected Item: {selectedItem?.id}</h1>
            <button onClick={() => setCount(count + 1)}>Increment</button>
        </div>
    );
};

export { HelloWorld };
