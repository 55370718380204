import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "../../queries";
import challengeSms from "../../services/redwoodMfa/challengeSms";
import challengePhone from "../../services/redwoodMfa/challengePhone";
import { REDWOOD_MFA_PHONE, REDWOOD_MFA_SMS } from "../../routes";
import { getAccuCode } from "../../services/accuCodeService";

interface RedwoodMfaTranslation {
    app: {
        common: {
            cancel: string;
        };
    };
    redwoodMfa: {
        callBtn: string;
        cancelBtn: string;
        redWoodMfaTitle: string;
        redwoodMfeDescription: string;
        textBtn: string;
        verificationDesc: string;
    };
}
type RedwoodMfaSubmit = "text" | "phone";

const LandingRedwoodMfa = () => {
    const { redwoodMfa } = useTranslations<RedwoodMfaTranslation>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const accuCode = getAccuCode();

    const handleAuthenticate = useCallback(
        async (value: RedwoodMfaSubmit) => {
            const csrfToken = window.csrf;
            if (csrfToken) {
                try {
                    if (value === "text") {
                        const authData = await challengeSms({ csrfToken });
                        if (
                            authData.authLevel === "USER_IDENTIFIED" &&
                            authData.status === "ACTIVE"
                        ) {
                            navigate(REDWOOD_MFA_SMS);
                        }
                    } else {
                        const authData = await challengePhone({ csrfToken });
                        if (
                            authData.authLevel === "USER_IDENTIFIED" &&
                            authData.status === "ACTIVE"
                        ) {
                            navigate(REDWOOD_MFA_PHONE);
                        }
                    }
                } catch (error) {
                    const errorMessage = (error as Error).message;
                    setErrorMessage(errorMessage);
                }
            }
        },
        [navigate]
    );
    const handleCancel = () => {
        window.location.href = `/participant/#/login?accu=${accuCode}`;
    };
    return (
        <div data-testid="landing-redwood-mfa">
            <div
                className="container row redwood-mra-container"
                data-testid="id-proof-error-component"
            >
                <div className="row">
                    <div className="registration col-xs-7 col-xs-offset-5">
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active">
                                <div className="align-center">
                                    <h1 className="signin-text-color">
                                        {redwoodMfa.redWoodMfaTitle}
                                    </h1>
                                    <p>{redwoodMfa.redwoodMfeDescription}</p>
                                    {errorMessage && (
                                        <p className="error-message">{errorMessage}</p>
                                    )}
                                </div>
                                <div className="button-group">
                                    <button
                                        className="button-container"
                                        onClick={() => handleAuthenticate("text")}
                                    >
                                        {redwoodMfa.textBtn}
                                    </button>
                                    <button
                                        className="button-container"
                                        onClick={() => handleAuthenticate("phone")}
                                    >
                                        {redwoodMfa.callBtn}
                                    </button>
                                </div>
                                <div className="cancel-btn-align">
                                    <button className="cancel-button" onClick={handleCancel}>
                                        {redwoodMfa.cancelBtn}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingRedwoodMfa;
