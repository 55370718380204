import React from "react";
import { Controller } from "react-hook-form";
import type { Control, RegisterOptions, UseFormRegister } from "react-hook-form";
import MaskedInput from "react-text-mask";
import type { RegisterFields } from "./types";

interface PhoneInputProps {
    control: Control<RegisterFields>;
    name: keyof RegisterFields;
    options?: RegisterOptions<RegisterFields>;
    register: UseFormRegister<RegisterFields>;
    useMask: boolean;
    value: string;
}

const PhoneInput = ({ register, name, options, useMask, value, control }: PhoneInputProps) => {
    const unmask = (val: string): string => {
        return val.replace(/\D/g, "");
    };

    return useMask ? (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({ field }) => (
                <MaskedInput
                    {...field}
                    mask={[
                        "(",
                        /\d/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                    ]}
                    guide={true}
                    showMask={true}
                    className="form-control"
                    type={"tel"}
                    onChange={(e) => {
                        const val = e.target.value;
                        field.onChange(unmask(val));
                    }}
                />
            )}
        />
    ) : (
        <input
            {...register(name, options)}
            type="tel"
            className="form-control"
            id={name}
            defaultValue={value}
        />
    );
};

export default PhoneInput;
