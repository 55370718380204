import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { EnterpriseChatComponent, EnterpriseChatPreLoginComponent } from "gw-shared-components";
import { useAuthData } from "../../queries";
import useGroupTxnAccess from "../../queries/useGroupTxnAccess";
import usePrimaryPlan from "../../queries/usePrimaryPlan";
import useAccuCustomization from "../../queries/useAccuCustomization";
import useInitialData from "../../queries/useInitialData";

const LANGUAGE = "en_US";

const EnterpriseChatContainer = () => {
    const { data: authData, isLoading: isAuthLoading } = useAuthData();
    const { data: userData, isLoading: isUserLoading } = useInitialData(authData?.authenticated);
    const { data: primaryPlanData, isLoading: isPrimaryPlanLoading } = usePrimaryPlan();
    const { data: accountCustomization, isLoading: isAccountCustomizationLoading } =
        useAccuCustomization(authData?.accuCode);
    const { gaId, indId } = primaryPlanData ?? {};
    const defaultCustomization = {};
    const { data: txnAccess, isLoading: isTxnLoading } = useGroupTxnAccess(
        gaId,
        indId,
        "WNCCHB,WNACHB,WNACHT"
    );
    const isPAE = globalThis.integratedEligibility.isEmulator;

    if (!authData?.authenticated) {
        return <EnterpriseChatPreLoginComponent />;
    }

    if (
        !userData ||
        isUserLoading ||
        isAuthLoading ||
        isPrimaryPlanLoading ||
        isTxnLoading ||
        isAccountCustomizationLoading ||
        !txnAccess
    ) {
        return null;
    }

    console.log("IS PAE", isPAE);

    const { firstName, middleName, lastName, name } = userData?.data?.users[0] ?? {};
    const { WNCCHB, WNACHB, WNACHT } = txnAccess;

    const accountDetails = {
        accountDetails: { firstName, middleName, lastName, name }
    };
    const activeGA = {
        individualId: indId ?? "",
        groupId: gaId ?? ""
    };

    const footerTranslations = selectTranslations("footer");

    return (
        <EnterpriseChatComponent
            account={accountDetails}
            activeGroupAccount={activeGA}
            accuCustomization={accountCustomization ?? defaultCustomization}
            authenticated
            chatBotEnable={WNACHB === "true"}
            enterpriseChatEnable={WNCCHB === "true"}
            isEmulator={isPAE}
            language={LANGUAGE}
            liveChatEnable={WNACHT === "true"}
            selectedLanguage={LANGUAGE}
            translations={{ footer: footerTranslations }}
        />
    );
};

export default EnterpriseChatContainer;
