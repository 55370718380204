import React, { Fragment } from "react";
import { isNull, isUndefined } from "lodash";
import PhoneInput from "./PhoneInput";
import { useTranslations } from "../../queries";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import accountSetupEvents from "../../../accountSetup/events/AccountSetupEvents";
import useContactDetails from "../../queries/useContactDetails/useContactDetails";
import type { AllFlowsContactInfoUpdateProps, Translations } from "./types";
import { useWatch } from "react-hook-form";
import CountrySelect from "./CountrySelect";

const AllFlowsContactInfoUpdate = ({
    register,
    trigger,
    errors,
    saveError,
    control
}: AllFlowsContactInfoUpdateProps) => {
    const { data: contactDetails, isLoading } = useContactDetails();
    const { profile, accountSetup }: Translations = useTranslations();

    const domesticCntryWatchVal = useWatch({ name: "contactCountryNameD", control });
    const intlCntryWatchVal = useWatch({ name: "contactCountryNameI", control });

    const usernameChange = (e: React.FormEvent) => {
        const target = e.target as HTMLInputElement;

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_FIELD,
            selection: accountSetupEvents.USRNM_CHANGE,
            payload: {
                username: target.value
            }
        });
    };

    const getCountryCode = (code: string): string => {
        return `+${code.slice(2)}`;
    };

    const stripPhoneNumber = (num: string): string => {
        return num ? num.replace(/\D/g, "") : "";
    };

    if (isLoading) return <div id="spinner" className="loader"></div>;

    const showDomesticMobileUS =
        contactDetails.domesticCntryCode === "US1" &&
        (domesticCntryWatchVal === "US1" || isUndefined(domesticCntryWatchVal));
    const showIntlMobileUS =
        contactDetails.intCntryCode === "US1" &&
        (intlCntryWatchVal === "US1" || isUndefined(intlCntryWatchVal));

    const countryCode = getCountryCode(
        contactDetails.domesticMobile
            ? domesticCntryWatchVal || contactDetails.domesticCntryCode
            : intlCntryWatchVal || contactDetails.intlCntryCode
    );

    return (
        <Fragment>
            {!isNull(saveError) && (
                <div className="form-group has-error">
                    <span className="help-block" role="alert">
                        {saveError?.code}
                    </span>
                </div>
            )}
            <div className={`form-group ${errors.email && "has-error"}`}>
                <label htmlFor="emailId" className="control-label">
                    {profile.personal.emailAddress}
                </label>
                <input
                    type="text"
                    {...register("email", {
                        required: accountSetup.emailRequired,
                        maxLength: 80,
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9\-'`]+[.])+[a-zA-Z]{2,}$/,
                            message: accountSetup.emailFormat
                        },
                        onBlur: (e) => {
                            trigger(e.target.name);
                            usernameChange(e);
                        }
                    })}
                    name="email"
                    id="emailId"
                    className="form-control"
                    defaultValue={contactDetails.personalEmailAddress}
                />
                {errors.email?.message && <p className="message">{errors.email.message}</p>}
                {errors.email?.type === "maxLength" && (
                    <p className="message">{accountSetup.emailMaxLength}</p>
                )}
                <span role="alert" className="help-block" aria-label={saveError?.email}>
                    {saveError?.email}
                </span>
            </div>

            {!isNull(contactDetails.domesticMobile) && !isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {accountSetup.contactInfo.phoneNumberOne}
                    </label>
                </div>
            )}

            {!isNull(contactDetails.domesticMobile) && isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {accountSetup.contactInfo.phoneNumberOnly}
                    </label>
                </div>
            )}

            {!isNull(contactDetails.domesticMobile) && (
                <div className="row">
                    {isNull(contactDetails?.intlNumber) && (
                        <div className="col-sm-6">
                            <CountrySelect
                                register={register}
                                trigger={trigger}
                                contactDetails={contactDetails}
                                name={"contactCountryNameD"}
                            />
                        </div>
                    )}

                    {!isNull(contactDetails.intlNumber) && (
                        <div className="col-sm-6">United States</div>
                    )}

                    {showDomesticMobileUS ? (
                        <div className="col-sm-6">
                            <div className={`input-group ${errors.phoneNumberIdD && "has-error"}`}>
                                <span className="input-group-addon dark-addon">{countryCode}</span>
                                <PhoneInput
                                    register={register}
                                    name={"phoneNumberIdD"}
                                    useMask
                                    aria-label={"phone_number_only"}
                                    value={stripPhoneNumber(contactDetails.domesticMobile)}
                                    control={control}
                                />
                            </div>
                            {errors.phoneNumberIdD?.message && (
                                <p className="message">{errors.phoneNumberIdD.message}</p>
                            )}
                        </div>
                    ) : (
                        <div className="col-sm-6">
                            <div className={`input-group ${errors.intlNumberIdD && "has-error"}`}>
                                <span className="input-group-addon dark-addon">{countryCode}</span>
                                <PhoneInput
                                    register={register}
                                    name={"intlNumberIdD"}
                                    options={{
                                        required: accountSetup.phoneNumberRequired,
                                        onBlur: (e) => trigger(e.target.name),
                                        maxLength: {
                                            value: 15,
                                            message: accountSetup.intlPhoneNumberMaxLength
                                        },
                                        pattern: /[^0-9]/g,
                                        setValueAs: (val) => stripPhoneNumber(val)
                                    }}
                                    useMask={false}
                                    aria-label={"phone_number_only"}
                                    value={stripPhoneNumber(contactDetails.domesticMobile)}
                                    control={control}
                                />
                            </div>
                            {errors.intlNumberIdD?.message && (
                                <p className="message">{errors.intlNumberIdD.message}</p>
                            )}
                        </div>
                    )}
                    <span id="phoneNumberError" className="help-block" role="alert">
                        {saveError?.phoneNumber}
                    </span>
                </div>
            )}

            {!isNull(contactDetails.domesticMobile) && !isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {accountSetup.contactInfo.phoneNumberTwo}
                    </label>
                </div>
            )}

            {isNull(contactDetails.domesticMobile) && !isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {accountSetup.contactInfo.phoneNumberOnly}
                    </label>
                </div>
            )}

            {!isNull(contactDetails.intlNumber) && (
                <div className="row">
                    <div className="col-sm-6">
                        <CountrySelect
                            register={register}
                            trigger={trigger}
                            contactDetails={contactDetails}
                            name={"contactCountryNameI"}
                        />
                    </div>

                    {showIntlMobileUS ? (
                        <div className="col-sm-6">
                            <div className={`input-group ${errors.phoneNumberIdI && "has-error"}`}>
                                <span className="input-group-addon dark-addon">{countryCode}</span>
                                <PhoneInput
                                    register={register}
                                    name={"phoneNumberIdI"}
                                    useMask
                                    aria-label={"phone_number_only"}
                                    value={stripPhoneNumber(contactDetails.intlNumber)}
                                    control={control}
                                />
                            </div>
                            {errors.phoneNumberIdI?.message && (
                                <p className="message">{errors.phoneNumberIdI.message}</p>
                            )}
                            <span id="phoneNumberError" className="help-block">
                                {saveError?.phoneNumber}
                            </span>
                        </div>
                    ) : (
                        <div className="col-sm-6">
                            <div className={`input-group ${errors.intlNumberIdI && "has-error"}`}>
                                <span className="input-group-addon dark-addon">{countryCode}</span>
                                <PhoneInput
                                    register={register}
                                    name={"intlNumberIdI"}
                                    options={{
                                        onBlur: (e) => trigger(e.target.name),
                                        required: accountSetup.phoneNumberRequired,
                                        maxLength: {
                                            value: 15,
                                            message: accountSetup.intlPhoneNumberMaxLength
                                        },
                                        pattern: /[^0-9]/g,
                                        setValueAs: (val) => stripPhoneNumber(val)
                                    }}
                                    useMask={false}
                                    aria-label={"phone_number_only"}
                                    value={stripPhoneNumber(contactDetails.intlNumber)}
                                    control={control}
                                />
                            </div>
                            {errors.intlNumberIdI?.message && (
                                <p className="message">{errors.intlNumberIdI.message}</p>
                            )}
                            <span id="phoneNumberError" className="help-block">
                                {saveError?.phoneNumber}
                            </span>
                        </div>
                    )}
                </div>
            )}
            <div className="disclosure-section">
                <p
                    id="disclosure-90-days-contact-info"
                    dangerouslySetInnerHTML={{
                        __html: profile.contactInfoDisclosure.replace("Continue", "Register")
                    }}
                />
            </div>
        </Fragment>
    );
};

export default AllFlowsContactInfoUpdate;
