import ReferenceData from "../../constants/ReferenceData";
import { executePostWithPcapAuth } from "../../utils/appUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

export interface AuthData {
    SP_HEADER_VERSION: String;
    authLevel: String;
    status: String;
    success: String;
    userGuid: String;
    username: String;
}
interface AuthenticateArgs {
    csrfToken: string;
}
export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain];
const challengeSms = async ({ csrfToken }: AuthenticateArgs): Promise<AuthData> => {
    const payload = {
        challengeReason: "DEVICE_AUTH",
        challengeMethod: "OP",
        apiClient: "WEB",
        bindDevice: false,
        csrf: csrfToken
    };
    const liveUrl = currentPcBaseApiUrl + ReferenceData.CALL_CHALLENGE_SMS_AUTH;
    const result = await executePostWithPcapAuth(liveUrl, "callChallengeSmsAuthFault", payload);
    return result.data.spHeader;
};

export default challengeSms;
