import React, { useState } from "react";
import AllFlowsContactInfoUpdate from "./AllFlowsContactInfoUpdate";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../queries";
import SubmitButton from "./SubmitButton";
import { useMutation } from "@tanstack/react-query";
import { getUpdateContactInfoJson } from "./helpers";
import { updateContact } from "../../services/accountSetup/accountSetupService";
import type { RegisterFields, SaveErrorData, SaveErrorState, SetupTranslations } from "./types";
import useRedirect from "../../hooks/useRedirect";
import { RedirectData } from "../../services/redirect/types";

const ContactUpdate = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        control
    } = useForm<RegisterFields>();
    const { accountSetup }: { accountSetup: SetupTranslations } = useTranslations();
    const [saveError, setSaveError] = useState<SaveErrorState | null>(null);
    const redirect = useRedirect();
    const mutation = useMutation({
        mutationFn: updateContact,
        onSuccess: (data: RedirectData) => {
            redirect(data);
        },
        onError: (err: SaveErrorData) => {
            const errData = err.data.error;
            setSaveError(errData);
        }
    });

    const updateContactDetails = (data, flowName) => {
        const payload = getUpdateContactInfoJson(data, flowName);
        mutation.mutate(payload);
    };

    const showSpinner = mutation.status === "pending";

    return (
        <div className="container row registration-container">
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
                <header className="contained-form-header">
                    <h1>{accountSetup.completeContactInfoTitle}</h1>
                    <p>{accountSetup.completeRegistrationInfo}</p>
                </header>

                <div className="inner-container with-padding with-shadow">
                    <form
                        id="registration-form"
                        name="accountSetupForm"
                        className="clearfix"
                        onSubmit={handleSubmit((data) => updateContactDetails(data, "mfa"))}
                        noValidate
                        autoComplete="off"
                    >
                        <AllFlowsContactInfoUpdate
                            register={register}
                            trigger={trigger}
                            errors={errors}
                            saveError={saveError}
                            control={control}
                        />
                        <SubmitButton showSpinner={showSpinner} isRegistrationFlow={false} />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUpdate;
