import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import {
    IDPROOF_CODE_ENTRY,
    IDPROOF_WITH_MAILPIN,
    IDPROOF_ERROR,
    CONTACT_UPDATE,
    REGISTER_INFO
} from "../../../routes";

import ContactUpdate from "../../accountSetup/ContactUpdate";
import RegisterInfo from "../../accountSetup/RegisterInfo";
import VerificationCodeEntryComponent from "./VerificationCodeEntryComponent";
import ErrorPage from "../../Application/ErrorPage";
import MailPinComponent from "./MailPinComponent";
import IdProofError from "./IDProofErrorComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../../queries/constants";
import { TranslationsServiceProvider } from "../../../providers/TranslationsServiceProvider";

interface Props {
    $state: { go: (path: string) => void };
    noCodeFunction: () => void;
    options: {
        isOtsUser: boolean;
        onCancel: () => void;
        onConfirm: (phoneNumber: string, data: []) => void;
        onOtpError: (state?: string) => void;
        phoneNumber: string;
    };
}

//TODO:This wrapper will remove once angular components completely migrated to react
const VerificationCodeEntryComponentWrapper = ({ $state, options, noCodeFunction }: Props) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route
                            path={IDPROOF_CODE_ENTRY}
                            element={
                                <VerificationCodeEntryComponent
                                    options={options}
                                    state={$state}
                                    noCodeFunction={noCodeFunction}
                                />
                            }
                        ></Route>
                        <Route path={IDPROOF_WITH_MAILPIN} element={<MailPinComponent />}></Route>
                        <Route path={IDPROOF_ERROR} element={<IdProofError />}></Route>
                        <Route
                            path={CONTACT_UPDATE}
                            element={<ContactUpdate />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={REGISTER_INFO}
                            element={<RegisterInfo />}
                            errorElement={<ErrorPage />}
                        />
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default VerificationCodeEntryComponentWrapper;
