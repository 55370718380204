import StringUtil from "core-ui/client/src/app/core/util/StringUtil";
import { isArray } from "lodash";

import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils";

let activeGroupAcct = {
    individualId: null,
    groupId: null
};
const accounts = [];
const summaryOfAllAccounts = {
    totalBalance: null,
    asOfDate: null
};

let accountDetails = null;
let primaryPlan = null;

/**
 * Utility function to convert an object to an array.
 */
const getObjAsArray = (obj) => {
    let result = [];
    if (obj) {
        result = isArray(obj) ? obj : [obj];
    }
    return result;
};

export const getActiveGroupAccount = () => {
    return activeGroupAcct;
};

export const getAccountSummary = () => {
    const getAccountSummarySuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const method = "getAccountSummary";
    const liveUrl = __baseApiUrl__ + ReferenceData.GET_ACCOUNT_SUMMARY;
    const payload = {};

    return executeGet(liveUrl, "getAccountSummaryFault", payload)
        .then(getAccountSummarySuccess)
        .catch((err) => {
            console.log(method + " Error: ", err);
            throw err;
        });
};

export const getPrimaryPlan = () => {
    const getPrimaryPlanSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const method = "getPrimaryPlan";
    const liveUrl = __baseApiUrl__ + ReferenceData.GET_PRIMARY_PLAN;

    return executeGet(liveUrl, "getPrimaryPlanFault")
        .then(getPrimaryPlanSuccess)
        .catch((err) => {
            console.log(method + " Error: ", err);
            throw err;
        });
};

export const getAccountDetails = (params) => {
    const getAccountDetailsSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const method = "getAccountDetails";
    const liveUrl =
        __baseApiUrl__ +
        StringUtil.supplant(ReferenceData.GET_ACCOUNT_DETAILS, {
            individualId: params.individualId,
            groupId: params.groupId
        });

    return executeGet(liveUrl, "getAccountDetailsFault")
        .then(getAccountDetailsSuccess)
        .catch((err) => {
            console.log(method + " Error: ", err);
            throw err;
        });
};

export const setPrimaryGroupAccount = () => {
    getPrimaryPlan().then(
        (plan) => {
            if (plan?.indId.length > 0 && plan?.gaId.length > 0) {
                primaryPlan = {
                    individualId: plan.indId,
                    groupId: plan.gaId
                };

                return { code: "0000", msg: "", data: primaryPlan };
            } else {
                return { code: "0001", msg: "Invalid primary plan", data: null };
            }
        },
        (err) => {
            console.error("ERROR - primaryPlanFactory: ", err);
            return { code: "0002", msg: err, data: null };
        }
    );
};

export const setAccounts = () => {
    if (accounts.length) {
        return accounts;
    } else {
        getAccountSummary.then(
            (data) => {
                // _.extend wasn't working..changed to push instead

                // set summary data
                summaryOfAllAccounts.asOfDate = data.asOfDate;
                summaryOfAllAccounts.totalBalance = data.totalBalance;

                const tmpAccounts = [];
                getObjAsArray(data.nonMonthlyAccounts).forEach(function (account) {
                    tmpAccounts.push(account);
                });
                getObjAsArray(data.monthlyAccounts).forEach(function (account) {
                    tmpAccounts.push(account);
                });
                // commented out support for credit accounts until these are supported...
                //getObjAsArray(data.creditAccounts).forEach(function(account) {
                //     tmpAccounts.push(account);
                //});
                //  accounts = service.sortPlans(tmpAccounts);

                return tmpAccounts;
            },
            (error) => {
                console.error("error settings accounts", error);
                return error;
            }
        );
    }
};

/**
 * Sets the active group account from the account hash in the URL or from an argument.
 */
export const setActiveGroupAccount = async (account) => {
    const method = "setActiveGroupAccount()";
    console.log(method, account);
    const indId = null;
    const gaId = null;

    if (account) {
        activeGroupAcct = account;
    } else if (!indId || !gaId) {
        const result = await setPrimaryGroupAccount();
        console.log("primary acct result", result);
        return result.data;
    }
};

export const setAccountDetails = (account) => {
    if (account) {
        /*   accountDetailsFactory.accountDetails.query(account, function (data) {
            accountDetails = data;
            logger.debug("{0}-    Got accountDetails. groupId={1}, accountUrl={2}", [
                method,
                accountDetails.groupId,
                accountDetails.accountURL
            ]);
            deferred.resolve(accountDetails);
        }); */
    } else {
        if (getActiveGroupAccount().groupId === null) {
            return setActiveGroupAccount().then(function (groupAcct) {
                getAccountDetails(groupAcct).then((data) => {
                    console.log("get account details 1", data);
                    accountDetails = data;
                    return accountDetails;
                });
            });
        } else {
            return getAccountDetails(activeGroupAcct).then((data) => {
                console.log("get account details 2", data);
                accountDetails = data;
                return accountDetails;
            });
        }
    }
};

export const getInitData = () => {
    const getResult = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.PARTICIPANT_INITIAL_DATA;

    return executeGet(liveUrl, "setAccuServiceFault")
        .then(getResult)
        .catch((err) => {
            console.log("setAccuService Error: ", err);
            throw err;
        });
};
