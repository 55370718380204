import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import LoginHelpMultiOptions from "./LoginHelpMultiOptions";
import LoginHelp from "./LoginHelp";
import {
    LOGIN_HELP_OPTIONS,
    LOGIN_HELP,
    LOGIN_HELP_NO_PASSWORD,
    LOGIN_HELP_LETS_TRY_ELSE,
    LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS
} from "../../../routes";
import NoPassword from "./NoPassword";
import LoginHelpLetsTryElse from "./LoginHelpLetsTryElse";
import LoginHelpResetPasswordDeliveryOptions from "./mfa/LoginHelpResetPasswordDeliveryOptions";

interface LoginHelpOptionsProps {
    $location: { path: (path: string) => void };
    $scope: { $apply: () => void; $root: { featureName: string } };
    accu: string;
}

/**
 * TODO: DELETE THIS COMPONENT WHEN ANGULAR IS REMOVED.
 * This is a temporary component that provides access to react router, react query, and the translations hook
 * while still allowing the new loginHelp components to be embedded in the Angular application.
 * Also temporarily pass the redirectService from the Angular app until that has been converted.
 */
const LoginHelpOptions = ({ accu, $location, $scope }: LoginHelpOptionsProps) => {
    const isReact = window.location.href.indexOf("isReact") > -1;

    return (
        !isReact && (
            <HashRouter>
                <Routes>
                    <Route
                        path={LOGIN_HELP_OPTIONS}
                        element={
                            <LoginHelpMultiOptions
                                accu={accu}
                                location={$location}
                                scope={$scope}
                            />
                        }
                    ></Route>

                    <Route path={LOGIN_HELP} element={<LoginHelp />}></Route>

                    <Route
                        path={LOGIN_HELP_NO_PASSWORD}
                        element={<NoPassword location={$location} scope={$scope} />}
                    ></Route>
                    <Route
                        path={LOGIN_HELP_LETS_TRY_ELSE}
                        element={
                            <LoginHelpLetsTryElse accu={accu} location={$location} scope={$scope} />
                        }
                    ></Route>
                    <Route
                        path={LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS}
                        element={
                            <LoginHelpResetPasswordDeliveryOptions
                                location={$location}
                                scope={$scope}
                            />
                        }
                    ></Route>
                </Routes>
            </HashRouter>
        )
    );
};

export default LoginHelpOptions;
