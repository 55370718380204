import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { LOGIN_HELP_NO_PASSWORD, LOGIN_HELP_WITH_ACCU } from "../../../routes";
import { getAccuCode } from "../../../services/accuCodeService";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../../queries";
import LoginHelpEvents from "./LoginHelpEvents";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import { LoginHelpOptionsFields } from "./types";
import eventBus from "../../../../utils/setEventBus";

interface LoginHelpOption {
    id: string;
    title: string;
}

interface LoginHelpOptionsProps {
    accu: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

interface LoginHelpOptionsTranslations {
    "0000": string;
    button: {
        continue: string;
    };
    loginHelp: string;
    loginHelpHow: string;
    loginHelpOptions: LoginHelpOption[];
    loginHelpSelectActivationMethodUpdated: string;
}

const DEFAULT_VALUES = {
    selectedOption: "",
    selectedValue: ""
};

const trackTimeLoad = function () {
    const signinTime = localStorage.getItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED);
    const loginHelpLoadedTime = new Date().getTime();
    localStorage.removeItem(PerformanceTrackingKeys.PT_LOGIN_HELP_LOADED);
    localStorage.setItem(PerformanceTrackingKeys.PT_LOGIN_HELP_LOADED, `${loginHelpLoadedTime}`);
    localStorage.setItem(
        PerformanceTrackingKeys.PT_SIGNIN_LOGIN_HELP_DELTA,
        `${loginHelpLoadedTime - parseInt(signinTime ? signinTime : "")}`
    );
};

const LoginHelpMultiOptions = ({ accu, location, scope }: LoginHelpOptionsProps) => {
    const {
        loginHelp,
        loginHelpSelectActivationMethodUpdated,
        loginHelpHow,
        loginHelpOptions,
        button,
        "0000": errorMessage
    } = useTranslations<LoginHelpOptionsTranslations>();

    const navigate = useNavigate();

    const getAccu = getAccuCode();
    const currentAccu = accu ? accu : getAccu ? getAccu : "Empower";
    const [deepLinkName, setDeepLinkName] = useState("");
    const initialValues = DEFAULT_VALUES;

    const {
        control,
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { isSubmitting, isValid }
    } = useForm<LoginHelpOptionsFields>({
        values: initialValues
    });

    // eslint-disable-next-line
    const { selectedOption, selectedValue } = watch();

    /**
     * Set up deep link flow in angular code from new login help page
     * @param href
     */
    useEffect(() => {
        trackTimeLoad();

        const extractErrorMessageFromRedirection = (href: string) => {
            if (String(href).includes("noScope")) {
                const start = String(href).indexOf("deepLinkParam") + 14;
                let deepLinkParam = String(href).substring(start);

                if (String(deepLinkParam).indexOf("iframe") !== -1) {
                    const deepLinkName = String(deepLinkParam).split("&");
                    deepLinkParam = deepLinkName[0];
                    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                    setDeepLinkName(deepLinkParam);
                }
            }
        };

        extractErrorMessageFromRedirection(window.location.href);

        if (scope && scope.$root && deepLinkName === "") {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setDeepLinkName(scope.$root.featureName);
        }
    }, [deepLinkName, scope]);

    const updateAngularRoutingState = useCallback(() => {
        /**
         * TODO: Delete this function when Angular is removed. Since we are using the React router
         * we need to update the Angular routing state to match the new React routing state.
         */
        if (location && scope) {
            location.path(LOGIN_HELP_WITH_ACCU + currentAccu);
            scope.$apply();
        }
    }, [location, scope, currentAccu]);

    const handleContinue = useCallback(
        async (formData: LoginHelpOptionsFields) => {
            const { selectedOption, selectedValue } = formData;
            if (selectedOption === "affiliate" || selectedValue === "affiliate") {
                try {
                    navigate("/" + LOGIN_HELP_WITH_ACCU + currentAccu, { replace: true });
                    updateAngularRoutingState();
                } catch (error) {
                    control.setError("root", { message: errorMessage });
                }
            } else {
                navigate("/" + LOGIN_HELP_NO_PASSWORD, {
                    replace: true,
                    state: { type: selectedOption }
                });
                updateAngularRoutingState();
            }
        },
        [control, errorMessage, navigate, updateAngularRoutingState, currentAccu]
    );

    // eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const dispatchAmplitudeEvent = useCallback((event: MouseEvent) => {
        const payload = String(event.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: payload,
            payload: {
                payload
            }
        });
    }, []);

    const handleRadioToggle = (e: MouseEvent) => {
        const payload = String(e.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, e.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: LoginHelpEvents.CLICK,
            payload: {
                payload
            }
        });

        setValue("selectedOption", e.currentTarget.id);
        setValue("selectedValue", e.currentTarget.id, { shouldValidate: true });
    };

    return (
        <form
            data-testid="loginhelp-options"
            className="loginhelp-container"
            onSubmit={handleSubmit(handleContinue)}
        >
            {isSubmitting && <div className="loader"></div>}
            <h1>{loginHelp}</h1>
            <div className="description">{loginHelpSelectActivationMethodUpdated}</div>
            <div className="description bold">{loginHelpHow}</div>

            <div>
                {loginHelpOptions.map((option) => (
                    <div className="row-item" key={option.id}>
                        <label data-selection={LoginHelpEvents.CLICK} htmlFor={option.id}>
                            <div className="radio-button-container">
                                <input
                                    id={option.id}
                                    data-testid={option.id}
                                    type="radio"
                                    value={option.id}
                                    aria-label={option.title}
                                    onClick={handleRadioToggle}
                                    {...register("selectedOption", { required: true })}
                                />
                                <span className="option-title">{option.title}</span>
                            </div>
                        </label>
                    </div>
                ))}
            </div>

            <div className="button-container">
                <button
                    id={button.continue}
                    className="btn btn-primary"
                    type="submit"
                    aria-label={button.continue}
                    data-selection={LoginHelpEvents.CTA_BUTTON}
                    onClick={dispatchAmplitudeEvent}
                    disabled={!isValid}
                >
                    {button.continue}
                </button>
            </div>
        </form>
    );
};

export default LoginHelpMultiOptions;
