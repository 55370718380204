import { type ErrorMessageParams, AuthActionTypes } from "../../useAuthenticateReducer";

import { sanitizeURL } from "../../../../utils/stringUtils";
import { Dispatch } from "react";
import { AuthAction } from "../../useAuthenticateReducer/useAuthenticateReducer";

export enum ErrorCode {
    ALTERNATE_LOGIN = "alternative.error.login",
    HARDSTOP = "logon.accu.hardstop",
    INCORRECT = "error.passcode.incorrect"
}

interface Error {
    attempts?: string;
    code: string;
    errors: Omit<ErrorMessageParams, "errorCode">[];
    message: string;
    status: string;
}

export interface ErrorResponse {
    data: null;
    error: Error;
    status: string;
}

export interface ErrorResponse2 {
    message: string;
    stack: string;
}

interface AuthenticationState {
    errorCode?: string;
    errorMessage?: string;
    errorMessageParams?: ErrorMessageParams;
    processing?: "failed" | "pending" | "loginsuccess" | "";
}

interface AuthenticateFailuresArgs {
    accuCode: string;
    dispatch: Dispatch<AuthAction>;
    error: any;
    loginDomain: string;
    setLoggingInState: (loading: boolean) => void;
    setShowLogin: (showLogin: boolean) => void;
}

const onAuthenticateFailure = ({
    error,
    dispatch,
    setLoggingInState,
    setShowLogin,
    loginDomain,
    accuCode
}: AuthenticateFailuresArgs): string | null => {
    dispatch({ type: AuthActionTypes.FAILED_AUTHENTICATION });

    if (error.data) {
        const err: ErrorResponse = error.data;
        if (err.status == "FAIL") {
            let errorCode = err.error.code;

            const payload: AuthenticationState = {
                errorMessage: errorCode,
                errorCode
            };

            dispatch({
                type: AuthActionTypes.SET_AUTHENTICATION_ERROR_CODE,
                payload
            });

            setLoggingInState(false);

            if (err.error.errors !== null) {
                const errorMessageParamsPayload = err.error.errors[0];

                dispatch({
                    type: AuthActionTypes.SET_AUTHENTICATION_ERROR_MESSAGE_PARAMS,
                    payload: { errorMessageParams: errorMessageParamsPayload }
                });

                if (errorMessageParamsPayload.code === ErrorCode.HARDSTOP) {
                    return loginDomain + "/registration/regError";
                }

                if (
                    errorMessageParamsPayload.code === ErrorCode.INCORRECT &&
                    errorMessageParamsPayload.attempts === "1"
                ) {
                    errorCode = "error.passcode.incorrect.redirect.help";
                    // noScope to indicate this is coming from iframe nad RegistrationController and other
                    // angular can see this is redirecting with no scope.
                    const errorCodeParam = errorCode.replaceAll(".", "-");
                    let redirectUrl = "/participant/#/loginHelp?errorMessage=true&attempts=done";
                    if (String(window.location.href).indexOf("optimizelyEndUserId") > -1) {
                        // handle no scope on optimzelyEndUserId
                        redirectUrl = `/participant/#/loginHelp?errorMessage=true&attempts=done&noScope=true&count=${errorMessageParamsPayload.attempts}&errorCodeParam=${errorCodeParam}`;
                    }
                    if (
                        String(accuCode).toLowerCase() === "empower" ||
                        String(accuCode).toLowerCase() === "empowerstaff" ||
                        String(accuCode).toLowerCase() === "myerira"
                    ) {
                        // redirect to loginHelpOptions page
                        redirectUrl = "/participant/#/loginHelpOptions?&accu=" + accuCode;
                        if (String(window.location.href).indexOf("optimizelyEndUserId") > -1) {
                            redirectUrl = `/participant/#/loginHelpOptions?&accu=${accuCode}&errorMessage=true&attempts=done&noScope=true&count=${errorMessageParamsPayload.attempts}`;
                        }
                    }

                    // eslint-disable-next-line
                    let redirectData = {
                        destinationUrl: redirectUrl,
                        from: "LOGIN_ERROR"
                    };
                    setShowLogin(false);

                    if (String(window.location.href).indexOf("/oauth") > -1) {
                        redirectData.destinationUrl =
                            window.location.pathname + window.location.hash;
                    }

                    return loginDomain + redirectData.destinationUrl;
                }
                if (errorCode === ErrorCode.ALTERNATE_LOGIN) {
                    const redirectUrl = sanitizeURL(errorMessageParamsPayload.ALTERNATIVE_LOGN_URL);
                    return redirectUrl;
                }
            }
        }
    }

    if (error.message) {
        const err: ErrorResponse2 = error;
        console.error(err.message);
    }

    return null;
};

export default onAuthenticateFailure;
