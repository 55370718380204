import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { VERIFY_CODE } from "../../../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslations } from "../../../../queries";
import { useForm } from "react-hook-form";
import LoginHelpEvents from "../LoginHelpEvents";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import eventBus from "../../../../../utils/setEventBus";

interface DeliveryOption {
    alt: string;
    id: string;
    smsDisclaimer?: boolean;
    title: string;
    url: string;
}

interface DeliveryOptionsFields {
    selectedOption: string | string[] | null | undefined;
    selectedValue: string;
}

interface ResetPasswordDeliveryOptionsTranslations {
    "0000": string;
    MFA_ActivationCodeDeliveryMissingInputException: string;
    forgotPasswordTitle: string;
    mfaDeliveryOptions: DeliveryOption[];
    mfaSendCode: string;
    resetPwMethod: string;
    sendActivationCodeAlert: string;
}

interface DeliveryOptionsProps {
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

const DEFAULT_VALUES = {
    selectedOption: "",
    selectedValue: ""
};

const trackTimeLoad = function () {
    const signinTime = localStorage.getItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED);
    const mfaLoadedTime = new Date().getTime();
    localStorage.removeItem(PerformanceTrackingKeys.PT_MFA_LOADED);
    localStorage.setItem(PerformanceTrackingKeys.PT_MFA_LOADED, `${mfaLoadedTime}`);
    localStorage.setItem(
        PerformanceTrackingKeys.PT_SIGNIN_MFA_DELTA,
        `${mfaLoadedTime - parseInt(signinTime ? signinTime : "")}`
    );
};

const LoginHelpResetPasswordDeliveryOptions = ({ location, scope }: DeliveryOptionsProps) => {
    const {
        forgotPasswordTitle,
        mfaSendCode,
        resetPwMethod,
        "0000": errorMessage,
        MFA_ActivationCodeDeliveryMissingInputException: mfaFormInvalidMessage
    } = useTranslations<ResetPasswordDeliveryOptionsTranslations>();

    const deliveryOptionsData = {
        deliverySet: [
            {
                deliveryType: "email:test@gmail.com"
            }
        ]
    };

    const navigate = useNavigate();

    const [deepLinkName, setDeepLinkName] = useState("");

    /**
     * Requirement: Selecting 'Resend code' from the verification code entry screen will route the user back to the delivery options selection screen,
     * defaulting to the previously selected delivery method.
     */
    const { state: previouslySelectedOption } = useLocation();

    /**
     * Requirement: If a user only has one method of contact (ex. email and no phone), that method will be the only one to display/selected by default.
     * Below option 'onlyPossibleOptionAndValue' applies to having one delivery method of contact with one associated value (ex. one email on file).
     */
    const onlyPossibleOptionAndValue = deliveryOptionsData?.deliverySet.length === 1 && {
        selectedOption: deliveryOptionsData.deliverySet[0].deliveryType.split(":")[0],
        selectedValue: deliveryOptionsData.deliverySet[0].deliveryType.split(":")[1]
    };

    const initialValues = previouslySelectedOption || onlyPossibleOptionAndValue || DEFAULT_VALUES;

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm<DeliveryOptionsFields>({
        values: initialValues
    });

    const legacyRedirect = false;
    const deliveryOptionsLoading = false;

    const deliveryOptions = [
        {
            id: "sms",
            title: "Text Me",
            url: "/ui/customization-ui/customizations/Default/images/mfa/text.svg",
            alt: "Text bubble icon"
        },
        {
            id: "voice",
            title: "Call Me",
            url: "/ui/customization-ui/customizations/Default/images/mfa/call.svg",
            alt: "Phone icon"
        },
        {
            id: "email",
            title: "Email Me",
            url: "/ui/customization-ui/customizations/Default/images/mfa/email.svg",
            alt: "Email icon"
        }
    ];

    /**
     * Set up deep link flow in angular code from new delivery options page
     * @param href
     */
    useEffect(() => {
        trackTimeLoad();

        const extractErrorMessageFromRedirection = (href: string) => {
            if (String(href).includes("noScope")) {
                const start = String(href).indexOf("deepLinkParam") + 14;
                let deepLinkParam = String(href).substring(start);

                if (String(deepLinkParam).indexOf("iframe") !== -1) {
                    const deepLinkName = String(deepLinkParam).split("&");
                    deepLinkParam = deepLinkName[0];
                    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                    setDeepLinkName(deepLinkParam);
                }
            }
        };

        extractErrorMessageFromRedirection(window.location.href);

        if (scope && scope.$root && deepLinkName === "") {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setDeepLinkName(scope.$root.featureName);
        }
    }, [deepLinkName, scope]);

    useEffect(() => {
        if (deliveryOptionsData?.deliverySet.length === 0) {
            // Route to Experian flow when no delivery options

            if (legacyRedirect) {
                //     legacyRedirect({ state: "ID_PROOFING_OTP_STATE" }, STATE_MAP_KEY);
            } else {
                /**
                 * TODO: When Experian flow is fully converted to React, we can either use
                 * navigate(ID_PROOF_OTP_VERIFY) here or down below loading block we can include
                 *      if (deliveryOptionsLoading || !deliveryOptionsData) {
                            return <div className="loader"></div>;
                        }

                        if (deliveryOptionsData.deliverySet.length === 0) {
                            return <Navigate to={ID_PROOF_OTP_VERIFY} />;
                        }
                 */
            }
        }
    }, [deliveryOptionsData?.deliverySet.length, legacyRedirect]);

    const updateAngularRoutingState = useCallback(() => {
        /**
         * TODO: Delete this function when Angular is removed. Since we are using the React router
         * to navigate from DeliveryOptions to VerificationCodeEntry, the Angular router state is not updated.
         * If the Angular router state is not updated and we try to route from VerificationCodeEntry
         * to any other Angular component, we encounter a bug where VerificationCodeEntry reloads
         * because the router state is out of sync. Using React router to navigate and updating
         * the Angular router state with this function allows us to conserve the state of the previously
         * selected delivery option without needing to lift it up to the Angular app.
         */
        if (location && scope) {
            location.path(VERIFY_CODE);
            scope.$apply();
        }
    }, [location, scope]);

    const handleSendCode = useCallback(
        async (formData: DeliveryOptionsFields) => {
            try {
                navigate(VERIFY_CODE, { state: formData });
                updateAngularRoutingState();
            } catch (error) {
                control.setError("root", { message: errorMessage });
            }
        },
        [control, errorMessage, navigate, updateAngularRoutingState]
    );

    const handleInvalid = useCallback(() => {
        control.setError("root", {
            message: mfaFormInvalidMessage
        });
    }, [control, mfaFormInvalidMessage]);

    // eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const dispatchAmplitudeEvent = useCallback((event: MouseEvent<HTMLElement>) => {
        const { selection } = event.currentTarget.dataset;
        const payload = event.currentTarget.dataset.payload || event.currentTarget.textContent;

        // This if else statements are not ideal. When we move away from GA tags, we should remove this statements.
        if (payload === "Send code") {
            eventBus.dispatch("MFAEvent.send_code_button_clicked_event", event.target);
        } else {
            eventBus.dispatch(
                "MFAEvent.activation_code_delivery_format_change_event",
                event.target
            );
        }
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: String(selection),
            payload: {
                payload
            }
        });
    }, []);

    if (deliveryOptionsLoading) {
        return <div className="loader"></div>;
    }

    return (
        <form
            data-testid="reset-password-delivery-options"
            className="loginhelp-container"
            onSubmit={handleSubmit(handleSendCode, handleInvalid)}
        >
            {isSubmitting && <div className="loader"></div>}
            <h2>{forgotPasswordTitle}</h2>
            <div className="description bold">{resetPwMethod}</div>
            <>
                {deliveryOptions.map((option) => (
                    <div key={option.id} className="row-item-delivery-options">
                        <label>
                            <div className="radio-button-container">
                                <input
                                    type="radio"
                                    aria-label={option.title}
                                    name="selectedOption"
                                    value={option.id}
                                    //  ref={register({ required: true })}
                                ></input>
                                <span className="option-title">{option.title}</span>
                            </div>
                        </label>
                        <img src={option.url} alt={option.alt}></img>
                    </div>
                ))}
            </>

            <div className="button-container">
                <button
                    className="btn btn-primary"
                    type="submit"
                    data-selection={LoginHelpEvents.CTA_BUTTON}
                    onClick={dispatchAmplitudeEvent}
                    disabled={!isValid}
                >
                    {mfaSendCode}
                </button>
            </div>
        </form>
    );
};

export default LoginHelpResetPasswordDeliveryOptions;

/*
{(deliveryOptionsError || errors?.root?.message) && (
    <div className="error-block" aria-live="polite">
        {deliveryOptionsError ? errorMessage : errors?.root?.message}
    </div>
)} */
