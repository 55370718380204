import { useQuery } from "@tanstack/react-query";
import { getInitData } from "../../services/account/AccountService";

const useInitialData = (authenticated?: boolean) => {
    return useQuery({
        queryKey: ["init-data"],
        queryFn: async () => {
            const data = await getInitData();
            return data;
        },
        enabled: authenticated
    });
};

export default useInitialData;
