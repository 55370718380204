import React from "react";

import ErrorPage from "./ErrorPage";
import { HelloWorld } from "../HelloWorld";
import {
    ACCOUNT_RECOVERY,
    ACCOUNT_RECOVERY_MULTIPLE_USERS,
    ACCOUNT_RECOVERY_CONFIRMATION,
    HELLO_WORLD,
    LOGIN,
    LOGIN_WITH_ACCU,
    LOGIN_HELP,
    LOGIN_HELP_OPTIONS,
    MFA_ACTIVATION_CODE_DELIVERY_OPTIONS,
    NPDI,
    NPDI_ACCOUNT_EXISTS,
    NPDI_ERROR,
    NPDI_LINK_ACCOUNTS,
    REGISTER,
    ROUTE_SIGNIN,
    SIGNIN_WITH_ACCU,
    MFA_VERIFY_CODE,
    ACCOUNT_OPEN_PERSONAL_DETAILS,
    ACCOUNT_OPEN_DETAILS,
    ACCOUNT_SETUP,
    CONTACT_UPDATE,
    REGISTER_INFO,
    REDWOOD_MFA,
    IDPROOF_WITH_PIN,
    IDPROOF_WITH_QUERY,
    IDPROOF_WITH_MAILPIN,
    IDPROOF_ERROR,
    IDPROOF_OTP_VERIFY,
    IDPROOF_CODE_ENTRY,
    NPDI_ACCOUNT_SETUP,
    OAUTH_LOGIN,
    OAUTH_LOGIN_WITH_ACCU,
    OAUTH_PRECONSENT,
    REDWOOD_MFA_SMS,
    REGISTRATION_SUMMARY,
    LOGIN_HELP_NO_PASSWORD,
    LOGIN_HELP_LETS_TRY_ELSE,
    LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS,
    REDWOOD_MFA_PHONE
} from "../../routes";
import IDProofErrorComponent from "../idProof/otp/IDProofErrorComponent";
import IdProofingWithPinComponent from "../idProof/otp/IdProofingWithPinComponent";
import IdProofQueryComponent from "../idProof/otp/IdProofQueryComponent";
import MailPinComponent from "../idProof/otp/MailPinComponent";
import LandingLoginContainer from "../../container/LandingLoginContainer";
import LoginHelp from "../accountRecovery/loginHelp/LoginHelp";
import LoginHelpMultiOptions from "../accountRecovery/loginHelp/LoginHelpMultiOptions";
import Register from "../register";

import { Route, HashRouter, Routes } from "react-router-dom";
import NpdiAccountExists from "../npdi/NpdiAccountExists";
import NpdiLinkAccounts from "../npdi/NpdiLinkAccounts";
import NpdiContainer from "../npdi/NpdiContainer";
import NpdiError from "../npdi/NpdiError";
import AccountRecoveryContainer from "../accountRecovery/container/AccountRecoveryContainer";
import AccountRecoveryMultipleUserContainer from "../accountRecovery/container/AccountRecoveryMultipleUserContainer";
import AccountRecoveryConfirmationContainer from "../accountRecovery/container/AccountRecoveryConfirmationContainer";
import VerificationCodeEntry from "../mfa/verificationCodeEntry/VerificationCodeEntry";
import AccountOpenDetailsForm from "../accountOpen/AccountOpenDetailsForm";
import AccountOpenPersonalDetailsForm from "../accountOpen/AccountOpenPersonalDetailsForm";
import AccountSetup from "../accountSetup/AccountSetup";
import ContactUpdate from "../accountSetup/ContactUpdate";
import RegisterInfo from "../accountSetup/RegisterInfo";
import IdentityVerificationComponent from "../idProof/otp/IdentityVerificationComponent";
import VerificationCodeEntryComponent from "../idProof/otp/VerificationCodeEntryComponent";
import AuthenticateError from "../authentication/AuthenticateError";

import { QueryParamProvider } from "../../providers/QueryParamProvider";
import DeliveryOptions from "../mfa/deliveryOptions/DeliveryOptions";
import OAuthContainer from "../oauth/container/OAuthContainer";
import Preconsent from "../oauth/preconsent/Preconsent";
import LandingRedwoodMfa from "../redwoodMfa/LandingRedwoodMfa";
import LandingSmsRedwoodMfa from "../redwoodMfa/LandingSmsRedwoodMfa";
import RegistrationSummary from "../register/registrationSummary/RegistrationSummary";
import NoPassword from "../accountRecovery/loginHelp/NoPassword";
import LoginHelpLetsTryElse from "../accountRecovery/loginHelp/LoginHelpLetsTryElse";
import LoginHelpResetPasswordDeliveryOptions from "../accountRecovery/loginHelp/mfa/LoginHelpResetPasswordDeliveryOptions";
import LandingPhoneRedwoodMfa from "../redwoodMfa/LandingRedwoodPhoneMfa";

export const Application = () => {
    return (
        <>
            <HashRouter>
                {/* Remove QueryParamProvider component when angular is removed */}
                <QueryParamProvider>
                    <Routes>
                        <Route
                            path={ACCOUNT_RECOVERY}
                            element={<AccountRecoveryContainer />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={ACCOUNT_RECOVERY_MULTIPLE_USERS}
                            element={<AccountRecoveryMultipleUserContainer />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={ACCOUNT_RECOVERY_CONFIRMATION}
                            element={<AccountRecoveryConfirmationContainer />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={HELLO_WORLD}
                            element={<HelloWorld />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={LOGIN}
                            element={<LandingLoginContainer isSSOLogin={false} />}
                            errorElement={<ErrorPage />}
                        />
                        <Route
                            path={"/loginPart"}
                            element={<LandingLoginContainer isSSOLogin={false} />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={LOGIN_WITH_ACCU}
                            element={<LandingLoginContainer isSSOLogin={false} />}
                            errorElement={<ErrorPage />}
                        />

                        <Route path={LOGIN_HELP} element={<LoginHelp />} />
                        <Route path={LOGIN_HELP_OPTIONS} element={<LoginHelpMultiOptions />} />
                        <Route path={LOGIN_HELP_NO_PASSWORD} element={<NoPassword />} />
                        <Route path={LOGIN_HELP_LETS_TRY_ELSE} element={<LoginHelpLetsTryElse />} />

                        <Route
                            path={LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS}
                            element={<LoginHelpResetPasswordDeliveryOptions />}
                        />

                        <Route
                            path={MFA_ACTIVATION_CODE_DELIVERY_OPTIONS}
                            element={<DeliveryOptions />}
                        />

                        <Route path={MFA_VERIFY_CODE} element={<VerificationCodeEntry />} />

                        <Route path={NPDI} element={<NpdiContainer />}>
                            <Route
                                path={NPDI_ACCOUNT_SETUP}
                                element={<AccountSetup flowName="npdiAccountSetupFlow" />}
                            />
                            <Route path={NPDI_ACCOUNT_EXISTS} element={<NpdiAccountExists />} />
                            <Route path={NPDI_LINK_ACCOUNTS} element={<NpdiLinkAccounts />} />
                            <Route path={NPDI_ERROR} element={<NpdiError />} />
                        </Route>

                        <Route
                            path={REGISTER}
                            element={<Register />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={ROUTE_SIGNIN}
                            element={<LandingLoginContainer isSSOLogin={false} />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={SIGNIN_WITH_ACCU}
                            element={<LandingLoginContainer isSSOLogin={false} />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={ACCOUNT_SETUP}
                            element={<AccountSetup flowName="registrationFlow" />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={CONTACT_UPDATE}
                            element={<ContactUpdate />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path={REGISTER_INFO}
                            element={<RegisterInfo />}
                            errorElement={<ErrorPage />}
                        />
                        <Route path={ACCOUNT_OPEN_DETAILS} element={<AccountOpenDetailsForm />} />
                        <Route
                            path={ACCOUNT_OPEN_PERSONAL_DETAILS}
                            element={<AccountOpenPersonalDetailsForm />}
                        />

                        <Route
                            path={REGISTRATION_SUMMARY}
                            element={<RegistrationSummary />}
                            errorElement={<ErrorPage />}
                        />

                        <Route
                            path="*"
                            element={
                                <AuthenticateError
                                    error="Invalid Route"
                                    errorTitle="Login Error"
                                    replace
                                />
                            }
                        />
                        <Route path={IDPROOF_WITH_PIN} element={<IdProofingWithPinComponent />} />
                        <Route path={IDPROOF_WITH_QUERY} element={<IdProofQueryComponent />} />
                        <Route
                            path={IDPROOF_WITH_MAILPIN}
                            element={<MailPinComponent optOut="optOut-Modal" />}
                        />
                        <Route path={IDPROOF_ERROR} element={<IDProofErrorComponent />} />
                        <Route
                            path={IDPROOF_OTP_VERIFY}
                            element={<IdentityVerificationComponent />}
                        />
                        <Route
                            path={IDPROOF_CODE_ENTRY}
                            element={<VerificationCodeEntryComponent />}
                        />
                        <Route path={REDWOOD_MFA} element={<LandingRedwoodMfa />} />
                        <Route path={REDWOOD_MFA_SMS} element={<LandingSmsRedwoodMfa />} />
                        <Route path={REDWOOD_MFA_PHONE} element={<LandingPhoneRedwoodMfa />} />
                        <Route path={OAUTH_LOGIN} element={<OAuthContainer />} />
                        <Route path={OAUTH_LOGIN_WITH_ACCU} element={<OAuthContainer />} />
                        <Route path={OAUTH_PRECONSENT} element={<Preconsent />} />
                    </Routes>
                </QueryParamProvider>
            </HashRouter>
        </>
    );
};
