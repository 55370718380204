export const DEFAULT_STALE_TIME = 300_000; // 5 minutes

export const QUERY_KEYS = {
    GET_AUTH_DATA: "getAuthData",
    GET_PSC_PATH: "getPSCPath",
    GET_DEFAULT_CUSTOM_RTR: "getDefaultCustomizationRetr",
    GET_DELIVERY_OPTIONS: "getDeliveryOptions",
    GET_PRE_LOGIN_ACCU_RETRIEVAL: "getPreLoginAccuRetrieval",
    SET_ACCOUNT_DETAILS: "setAccountDeatils",
    SET_ACCU_SERVICE: "setAccuService",
    GET_DETAILS: "retrieve-details",
    GET_COUNTRY_CODE: "retrieveCountryCode",
    GET_STATE_CODE: "retrieveStateCode",
    GET_SITE_CONTACT_INFO: "getSiteContactInfo",
    GET_DEFAULT_ACCTS_CUST_RETRIEVAL: "getDefaultAcctsCustRetrievalList",
    GET_RETRIEVE_IDPROOF_QUERIES: "retrieveIDProofQueries",
    ELIGIBILITY_LITE: "eligibilityLite",
    GET_REGISTRATION_SUMMARY: "getRegistrationSummary",
    PARTICIPANT_CONSENT: "participantConsent",
    GET_BENEFICIARIES: "getBeneficiaries",
    GROUP_TXN_ACCESS: "groupTxnAccess",
    GET_PRIMARY_PLAN: "getPrimaryPlan",
    GET_OAUTH_CONSENT_URL: "getOAuthConsentUrl",
    DENY_OAUTH_CONSENT: "denyOauthConsent",
    ACCEPT_OAUTH_CONSENT: "acceptOauthConsent",
    CHALLENGE_PASSWORD_FREEMIUM: "challengePasswordFreemium",
    VERIFY_EMAIL_ADDRESS: "verifyEmailAddress"
};
