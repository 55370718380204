import React, { FocusEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "../../../queries";
import { formatDate, formatSSN, stripNonAlphanumeric, validSSN } from "../../../utils/formUtils";
import DateUtil from "core-ui/client/src/app/DateUtil";
import ControlledFormField from "../../shared/controlledFormField/ControlledFormField";
import { stripNonDigits } from "../../../utils/formUtils/stripNonDigits";
import { getAccuCode } from "../../../services/accuCodeService";
import registerWithoutPin from "../../../services/register/registerWithoutPin";
import useRedirect from "../../../hooks/useRedirect";
import handleRegisterServiceError from "../../../services/register/handleRegisterServiceError";
import { LOGIN_HELP_LETS_TRY_ELSE } from "../../../routes";

interface RegisterWithoutPinTranslations {
    app: { beneficiary: { bene: { validationMessages: { socialSecurityNumberInvalid: string } } } };
    button: { continue: string };
    dateOfBirthFutureDateNotAllowed: string;
    dateOfBirthInvalid: string;
    dateOfBirthRequired: string;
    dateOfBirthYearOutOfRange: string;
    idProofingEnabled: string;
    individual: {
        dateOfBirth: string;
        ssn: string;
        zipCode: string;
    };
    logon: { [key: string]: string };
    ssnRequired: string;
    zipcodeRequired: string;
}

interface RegisterWithoutPinProps {
    flowName: string;
}

interface RegisterWithoutPinFields {
    dateOfBirth: string;
    ssn: string;
    zipCode: string;
}

const DEFAULT_VALUES = {
    ssn: "",
    zipCode: "",
    dateOfBirth: ""
};

const RegisterWithoutPin = ({ flowName }: RegisterWithoutPinProps) => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting }
    } = useForm<RegisterWithoutPinFields>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur",
        criteriaMode: "all"
    });
    const {
        app,
        button,
        dateOfBirthFutureDateNotAllowed,
        dateOfBirthInvalid,
        dateOfBirthRequired,
        dateOfBirthYearOutOfRange,
        idProofingEnabled,
        individual,
        logon: errorMessages,
        ssnRequired,
        zipcodeRequired
    } = useTranslations<RegisterWithoutPinTranslations>();
    const [typeSSN, setTypeSSN] = useState("text");
    const [typeDateOfBirth, setTypeDateOfBirth] = useState("text");
    const redirect = useRedirect();
    const navigate = useNavigate();
    const accuCode = getAccuCode();
    /**
     * The following defines the validations for each field. Each validation must return undefined
     * if the rule is not violated in order to remove it from the field's error object.
     */
    const SSN_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? ssnRequired : undefined),
            isValid: (value: string) =>
                !validSSN(value)
                    ? app.beneficiary.bene.validationMessages.socialSecurityNumberInvalid
                    : undefined
        }
    };
    const ZIP_CODE_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? zipcodeRequired : undefined)
        }
    };
    const DATE_OF_BIRTH_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? dateOfBirthRequired : undefined),
            isValid: (value: string) =>
                !DateUtil.isValid(value, "MM/DD/YYYY", true) ? dateOfBirthInvalid : undefined,
            isOutOfRange: (value: string) =>
                DateUtil.isBefore(value, DateUtil.getMinimumDateOfBirth())
                    ? dateOfBirthYearOutOfRange
                    : undefined,
            isFutureDate: (value: string) =>
                DateUtil.isAfter(value, DateUtil.getDate())
                    ? dateOfBirthFutureDateNotAllowed
                    : undefined
        }
    };

    /**
     * When the SSN field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurSSN = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (validSSN(event.target.value)) {
            setTypeSSN("password");
        }
    };

    /**
     * When the SSN field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusSSN = () => {
        setTypeSSN("text");
    };

    /**
     * When the Date of Birth field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurDateOfBirth = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (DateUtil.isValid(event.target.value, "MM/DD/YYYY", true)) {
            setTypeDateOfBirth("password");
        }
    };

    /**
     * When the Date of Birth field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusDateOfBirth = () => {
        setTypeDateOfBirth("text");
    };

    const onSubmit = async (formData: RegisterWithoutPinFields) => {
        const { dateOfBirth, ssn, zipCode } = formData;
        const payload = {
            flowName,
            accu: getAccuCode(),
            dateOfBirth: stripNonDigits(dateOfBirth),
            isIDProofing: idProofingEnabled,
            ssn: stripNonDigits(ssn),
            zipcode: zipCode
        };
        try {
            const { data } = await registerWithoutPin(payload);
            if (data.state === "ID_PROOFING_CONSENT") {
                // TODO (Maja): Trigger ID Proof Consent Modal
                // Dependency on WCDX-21096
            } else {
                redirect(data);
            }
        } catch (err) {
            if (
                flowName === "loginHelp" &&
                (accuCode === "Empower" || accuCode === "EmpowerStaff" || accuCode === "MYERIRA")
            ) {
                navigate(LOGIN_HELP_LETS_TRY_ELSE, { replace: true });
            } else {
                const message = handleRegisterServiceError(errorMessages, err);
                setError("root", { message });
            }
        }
    };

    return (
        <form data-testid="register-without-pin" onSubmit={handleSubmit(onSubmit)}>
            {isSubmitting && (
                <div className="loaderBackground">
                    <div className="loader"></div>
                </div>
            )}
            {errors?.root?.message && (
                <div
                    data-testid="register-without-pin-error"
                    className="error-block margin-bottom-100"
                    aria-live="polite"
                >
                    {errors.root.message}
                </div>
            )}
            <ControlledFormField
                className="col-5"
                control={control}
                id="ssn"
                label={individual.ssn}
                name="ssn"
                onBlur={handleBlurSSN}
                onChange={formatSSN}
                onFocus={handleFocusSSN}
                rules={SSN_RULES}
                type={typeSSN}
            />
            <ControlledFormField
                className="col-5"
                control={control}
                id="zip-code"
                label={individual.zipCode}
                maxLength={10}
                name="zipCode"
                onChange={stripNonAlphanumeric}
                rules={ZIP_CODE_RULES}
            />
            <ControlledFormField
                control={control}
                id="date-of-birth"
                label={individual.dateOfBirth}
                name="dateOfBirth"
                onBlur={handleBlurDateOfBirth}
                onChange={formatDate}
                onFocus={handleFocusDateOfBirth}
                rules={DATE_OF_BIRTH_RULES}
                type={typeDateOfBirth}
            />
            <div className="form-group">
                <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                    id="login-help-submit"
                >
                    <span>{button.continue.toUpperCase()}</span>
                </button>
            </div>
        </form>
    );
};

export default RegisterWithoutPin;
