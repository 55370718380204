const idProofQueriesTemplate = `
<div class="col-md-10 col-md-offset-1 registration" data-ng-init="retrieveIDProofQueries()">
    <header class="contained-form-header">
        <h1>We need to verify a few more things</h1>
        <p>Please answer the following questions.</p>
    </header>

    <div class="inner-container with-padding with-shadow">
      <div id="frame-container-spinner" class="loaderBackground just-element" ng-if="idProofCtrl.loading">
    <div class="loader"></div>
</div>
<form role="form" name="idProofingQueryForm" data-ng-submit='submitIDProofAnswers()'>
<div ng-repeat="idProofingQuestion in idProofingQuestions track by $index" ng-init="questionIndex = $index">
    <dl class="pw-steps">
        <dt id="label-proofingQuestion-{{$index}}">{{idProofingQuestion.displayQuestion}}</dt>
            <dd>
               <fieldset>
                 <legend aria-labelledby="label-proofingQuestion-{{$index}}" class="sr-only"></legend>
                    <div class="radio" ng-repeat="options in idProofingQuestion.options track by $index">
                        <label for="idProof-option-{{questionIndex}}-{{$index}}">

                            <input id="idProof-option-{{questionIndex}}-{{$index}}"
                                type="checkbox"                                      
                                name="selectedIDProofOption{{questionIndex}}"
                                ng-value="{{options}}"
                                ng-model="selectedAnswerValue"                                       
                                ng-click="handleIDProofOptionSelection(idProofingQuestion, options,selectedAnswerValue )"
                                ng-if="idProofingQuestion.multiSelect == true"/>
                            <input id="idProof-option-{{questionIndex}}-{{$index}}"
                                type="radio"      
                                required                                                         
                                name="selectedIDProofOption{{questionIndex}}"
                                ng-value="{{options}}"
                                ng-model="selectedAnswerValue"                                       
                                ng-click="handleIDProofOptionSelection(idProofingQuestion, options , selectedAnswerValue)"
                                ng-if="idProofingQuestion.multiSelect == false"/> 
                                          
                            {{options.option}}
                        </label>
                    </div>
              </fieldset>
           </dd>
       </dl>
    </div>


    <div class="text-right margin-top-default">
        <button type="submit"
                class="btn btn-primary"
                ng-disabled="showSpinner==true"
                id="idProofQueriesSubmit"                       
                >
                <span data-ng-hide="showSpinner==true">
                    Submit
                    </span>
                                            
                <span data-ng-show="showSpinner==true">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                    Processing ...
                </span>
                
        </button>
    </div>
</form>
`;
export default idProofQueriesTemplate;
