import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import optOutModalAndReqPin from "./services/optOutModalAndReqPin";
import optOutWithExpiredOTPAndReqPin from "./services/optOutWithExpiredOTPAndReqPin";
import optOutQueryAndReqPin from "./services/optOutQueryAndReqPin";
import optOutErrorAndReqPin from "./services/optOutErrorAndReqPin";
import { IDPROOF_ERROR } from "../../../routes";
import { useTranslations } from "../../../queries";
import { useQuery } from "@tanstack/react-query";
import { getSiteContactInfoPreLogin } from "core-ui/client/react/core/services/siteContactInfoServicePreLogin.js";
import { getDefaultAcctsCustRetrievalList } from "core-ui/client/react/core/services/defaultAcctsCustRetrievalService.js";
import { QUERY_KEYS } from "../../../queries/constants/index";

interface MailPinComponentTranslations {
    mailPinAOR: string;
    mailPinBody: string;
    mailPinDefaultHeading: string;
    otpExpiredHeading: string;
    pinRequestedHeding: string;
}

const MailPinComponent = () => {
    const [mailPinHeading, setMailPinHeading] = useState("text");
    const [optOut, setoptOut] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const optOutVal = location?.state?.optOut;
    const { data: defaultAcctsCustRetrievalList, isLoading: accCustRetriveLoading } = useQuery({
        queryKey: [QUERY_KEYS.GET_DEFAULT_ACCTS_CUST_RETRIEVAL],
        queryFn: () => getDefaultAcctsCustRetrievalList()
    });
    const contactInfoPayload = {
        accu: defaultAcctsCustRetrievalList?.global.accuCode,
        accuSpecificPrimDb: defaultAcctsCustRetrievalList?.primaryDatabase.preLoginPrimaryDB,
        portalType: "TOLLFREE"
    };
    const { data: siteContactInfo, isLoading: loadingContactInfo } = useQuery({
        queryKey: [
            QUERY_KEYS.GET_SITE_CONTACT_INFO,
            contactInfoPayload.accu,
            contactInfoPayload.accuSpecificPrimDb,
            contactInfoPayload.portalType
        ],
        queryFn: () =>
            getSiteContactInfoPreLogin({
                accu: contactInfoPayload.accu,
                accuSpecificPrimDb: contactInfoPayload.accuSpecificPrimDb,
                portalType: contactInfoPayload.portalType
            }),
        enabled: Boolean(contactInfoPayload) // will this ever be disabled?
    });
    useEffect(() => {
        if (optOutVal !== null) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setoptOut(optOutVal);
        }
    }, [optOutVal]);
    const {
        pinRequestedHeding,
        mailPinAOR,
        mailPinBody,
        otpExpiredHeading,
        mailPinDefaultHeading
    } = useTranslations<MailPinComponentTranslations>();
    const updatedMailBody = mailPinBody.replace(
        "{{phone}}",
        `${siteContactInfo?.phone ? siteContactInfo.phone : "1-855-756-4738"}`
    );

    const determineMailPinHeading = (
        optOut,
        pinRequestedHeading,
        otpExpiredHeading,
        mailPinDefaultHeading
    ) => {
        if (optOut === "optOut-Modal" || optOut === "optOut-Query") {
            return pinRequestedHeading || "";
        } else if (optOut === "otp-Expired") {
            return otpExpiredHeading || "";
        } else {
            return mailPinDefaultHeading || "";
        }
    };

    useEffect(() => {
        const heading = determineMailPinHeading(
            optOut,
            pinRequestedHeding,
            otpExpiredHeading,
            mailPinDefaultHeading
        );
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setMailPinHeading(heading);
    }, [optOut, pinRequestedHeding, otpExpiredHeading, mailPinDefaultHeading]);

    const getResponseData = useCallback(
        (result) => {
            const { data } = result;
            if (data !== null && data.status == "successful") {
                //TODO:Need to intergrate partial auth service
            } else if (!data.success) {
                navigate(IDPROOF_ERROR, { replace: true, state: { errorCode: "idProofOTS" } });
            }
        },
        [navigate]
    );
    useEffect(() => {
        const isOptOutParams = {
            optOut: optOut
        };
        const triggerService = async () => {
            const optOutActions = {
                "optOut-Modal": optOutModalAndReqPin,
                "otp-Expired": optOutWithExpiredOTPAndReqPin,
                "optOut-Query": optOutQueryAndReqPin,
                "optOut-Error": optOutErrorAndReqPin
            };

            const action = optOutActions[optOut];
            if (action) {
                try {
                    const result = await action(isOptOutParams);
                    getResponseData(result);
                } catch (error) {
                    console.log(error);
                    return error;
                }
            }
        };
        triggerService();
    }, [getResponseData, optOut, navigate]);

    if (accCustRetriveLoading || loadingContactInfo) {
        return (
            <div className="loaderBackground">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="container row" data-testid="mail-pin-component">
            <div className="row">
                <div className="registration col-xs-6 col-xs-offset-5">
                    <header className="contained-form-header" data-testid="mail-pin-heading">
                        <h1>{mailPinHeading}</h1>
                    </header>

                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active">
                            <p>
                                <strong>{mailPinAOR}</strong>
                            </p>
                            <p>{updatedMailBody}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MailPinComponent;
