import React, { useState, useEffect } from "react";

interface LoginErrorProps {
    error: string;
    errorTitle: string;
}

const AuthenticateError = ({ errorTitle, error }: LoginErrorProps) => {
    const [errorMessage, setErrorMessage] = useState(error);

    useEffect(() => {
        if (error) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setErrorMessage(error);
        }
    }, [error]);

    return (
        <div className="container with-top-margin">
            <div className="container row" style={{ height: "50px" }}>
                <div className="row">
                    <div
                        className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
                        data-testid="login-error"
                    >
                        <header className="contained-form-header margin-top-default">
                            <h1>{errorTitle}</h1>
                        </header>

                        <div id="content-container">
                            <p className="login-error">{errorMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthenticateError;
