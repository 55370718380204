import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "../../../queries";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IDPROOF_WITH_MAILPIN, IDPROOF_ERROR } from "../../../routes";
import retrieveIDProofQueries from "./services/retrieveIDProof";
import submitIDProofAnswers from "./services/submitIDProofQuestions";
import { QUERY_KEYS } from "../../../queries/constants/index";
import createShadowAccountOTS from "../../accountOpen/services/createShadowAccountOTS";
import useRedirect from "../../../hooks/useRedirect";

interface IdProofingQueryTranslations {
    idProofQueDesc: string;
    idProofQueHeading: string;
    idProofSubmit: string;
    skipPin: string;
}

type SubmittedValue = {
    id: string;
};

interface SelectedAnswerValue {
    [key: number]: string;
}
interface IdProofingQuestionOption {
    id: string;
    option: string;
    selected: boolean;
}

interface IdProofingQuestion {
    displayQuestion: string;
    id: string;
    multiSelect: boolean;
    options: IdProofingQuestionOption[];
}
interface IdProofQuestionData {
    idProofingQuestions: IdProofingQuestion[];
}

export const IdProofQueryComponent = () => {
    const [selectedAnswerValue, setSelectedAnswerValue] = useState<SelectedAnswerValue>({});
    const [submittedValue, setSubmittedValue] = useState<SubmittedValue[]>([]);
    const [isOtsUser, setIsOtsUser] = useState<boolean>(false);
    const [userPhoneNumber, setPhoneNumber] = useState<string>("");
    const {
        formState: { isSubmitting }
    } = useForm();
    const { idProofQueDesc, idProofQueHeading, skipPin, idProofSubmit } =
        useTranslations<IdProofingQueryTranslations>();
    const { data: idProofQuestionData } = useQuery<IdProofQuestionData>({
        queryKey: [QUERY_KEYS.GET_RETRIEVE_IDPROOF_QUERIES],
        queryFn: () => retrieveIDProofQueries()
    });

    const navigate = useNavigate();
    const location = useLocation();
    const redirect = useRedirect();
    const flowName = location?.state?.flowName;
    const phoneNumber = location?.state?.phoneNumber;
    useEffect(() => {
        if (phoneNumber !== undefined) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setPhoneNumber(phoneNumber);
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (flowName !== null) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setIsOtsUser(flowName === "OTS");
        }
    }, [flowName]);

    const handleIDProofOptionSelection = useCallback(
        (displayQuestion, qusOptions) => {
            setSelectedAnswerValue((prevAnswer) => {
                return {
                    ...prevAnswer,
                    [displayQuestion.id]: qusOptions.id
                };
            });
            displayQuestion.options.forEach((origOptions) => {
                origOptions.selected = origOptions.id === qusOptions.id;
            });

            const index = submittedValue?.findIndex((item) => item.id === displayQuestion.id);
            if (index !== -1) {
                submittedValue[index] = displayQuestion;
            } else {
                setSubmittedValue([...submittedValue, displayQuestion]);
            }
        },
        [submittedValue]
    );
    const handleSubmit = async () => {
        event?.preventDefault();
        const idProofSelDataObj = {
            idProofData: {
                idProofSelData: submittedValue
            }
        };
        try {
            const result = await submitIDProofAnswers(idProofSelDataObj);
            if (result) {
                if (result.status == "fail" && result.errorCode != null) {
                    if (isOtsUser) {
                        navigate(IDPROOF_ERROR, {
                            replace: true,
                            state: { errorCode: "idProofOTS" }
                        });
                    } else {
                        navigate(IDPROOF_WITH_MAILPIN, {
                            replace: true,
                            state: { optOut: "optOut-Query" }
                        });
                    }
                } else {
                    if (result.flowName === "otsAccountSetup") {
                        try {
                            const { data } = await createShadowAccountOTS(userPhoneNumber);
                            redirect(data, { flowName: "OTS" });
                        } catch (error) {
                            navigate(IDPROOF_ERROR);
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error);
            navigate(IDPROOF_ERROR);
        }
    };
    const onOptOutClick = () => {
        navigate(IDPROOF_WITH_MAILPIN, { replace: true, state: { optOut: "optOut-Query" } });
    };
    return (
        <div className="container row" data-testid="id-proofing-with-pin-component">
            {isSubmitting && <div className="loader"></div>}
            <div className="row">
                <div className="registration col-xs-8 col-xs-offset-2">
                    <header className="contained-form-header">
                        <h1>{idProofQueHeading}</h1>
                        <p>{idProofQueDesc}</p>
                    </header>

                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="registrationContent">
                            <form id="ProofingQueryForm" onSubmit={handleSubmit}>
                                <div className="id-query-align">
                                    {(
                                        idProofQuestionData as IdProofQuestionData
                                    )?.idProofingQuestions.map((displayQuestion, questionIndex) => (
                                        <React.Fragment key={displayQuestion.id}>
                                            <label
                                                key={displayQuestion.id}
                                                className="id-query-margin"
                                            >
                                                {displayQuestion.displayQuestion}
                                            </label>
                                            {displayQuestion.options.map((qusOptions, index) => (
                                                <>
                                                    <label
                                                        key={qusOptions.id}
                                                        className="id-query-margin"
                                                        style={{ fontWeight: "normal" }}
                                                        htmlFor={`idProof-option-${questionIndex}-${index}`}
                                                    >
                                                        <input
                                                            id={`idProof-option-${questionIndex}-${index}`}
                                                            type="radio"
                                                            name={`selectedIdProofOption${questionIndex}`}
                                                            value={JSON.stringify(qusOptions)}
                                                            checked={
                                                                selectedAnswerValue[
                                                                    displayQuestion.id
                                                                ] == qusOptions.id
                                                            }
                                                            onChange={() =>
                                                                handleIDProofOptionSelection(
                                                                    displayQuestion,
                                                                    qusOptions
                                                                )
                                                            }
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        {qusOptions.option}
                                                    </label>
                                                </>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </div>

                                <div className="text-right margin-top-default">
                                    {isOtsUser && (
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            id="idProofQueriesOptout"
                                            onClick={onOptOutClick}
                                        >
                                            {skipPin}
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                        id="idProofQueriesSubmit"
                                    >
                                        {idProofSubmit}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default IdProofQueryComponent;
