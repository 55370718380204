import React, { useEffect, useState } from "react";

interface AccountRecoveryErrorLabelProps {
    completed?: boolean;
    error: string;
    formValidationStyle?: boolean;
}
const AccountRecoveryErrorLabel = ({
    error,
    completed,
    formValidationStyle = true
}: AccountRecoveryErrorLabelProps) => {
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (!completed) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setHide(false);
            return;
        }
        setTimeout(() => setHide(true), 500);
    }, [completed]);

    if (hide) {
        return "";
    }

    return (
        <div
            className={`${
                completed ? "success" : formValidationStyle && "form-validation-rule"
            } animated`}
        >
            {completed && <span className="fa-solid fa-check check-error-label"></span>}
            <span>{error}</span>
        </div>
    );
};

export default AccountRecoveryErrorLabel;
