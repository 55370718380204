import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { IDPROOF_OTP_VERIFY } from "../../../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../../queries/constants";
import { TranslationsServiceProvider } from "../../../providers/TranslationsServiceProvider";
import IdentityVerificationComponent from "./IdentityVerificationComponent";

//TODO:This wrapper will remove once angular components completely migrated to react
interface IdentityVerificationComponentProps {
    isOtsUser: boolean;
    options: {
        onCancel?: () => void;
        onConfirm?: (phoneNumber: string, data: any) => void;
    };
    state: { go: (path: string) => void };
}
const IdentityVerificationComponentWrapper = ({
    state,
    isOtsUser,
    options
}: IdentityVerificationComponentProps) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route
                            path={IDPROOF_OTP_VERIFY}
                            element={
                                <IdentityVerificationComponent
                                    state={state}
                                    isOtsUser={isOtsUser}
                                    options={options}
                                />
                            }
                        ></Route>
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default IdentityVerificationComponentWrapper;
