import React, { MouseEvent, useCallback, useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "../../../queries";
import LoginHelpEvents from "./LoginHelpEvents";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";

import eventBus from "../../../../utils/setEventBus";
import { QUERY_KEYS } from "../../../queries/constants";
import { callChallengeResetPasswordFreemium } from "../../../services/accountRecovery/AccountRecoveryService";
import {
    LOGIN_HELP_LETS_TRY_ELSE,
    LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS
} from "../../../routes";
import { getCookie } from "../../../services/accuCodeService";

interface LoginHelpNoPasswordProps {
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}
interface LoginHelpOptionsTranslations {
    button: {
        close: string;
        continue: string;
    };
    checkEmailNext: string;
    emailAddrTitle: string;
    emailFormat: string;
    emailLinkToResetPW: string;
    emailRequired: string;
    forgotPasswordTitle: string;
    sentEmailLink: string;
}

const emailId = "Email Address";

const NoPassword = ({ location, scope }: LoginHelpNoPasswordProps) => {
    const {
        button,
        checkEmailNext,
        emailAddrTitle,
        emailFormat,
        emailLinkToResetPW,
        emailRequired,
        forgotPasswordTitle,
        sentEmailLink
    } = useTranslations<LoginHelpOptionsTranslations>();

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        register,
        trigger,
        setFocus,
        formState: { errors, isSubmitting, isValid }
    } = useForm();

    const { state } = useLocation();
    const flowType = state?.type;
    const accu = getCookie("accu");

    const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9\-'`]+[.])+[a-zA-Z]{2,}$/;

    const [continueClicked, setContinueClicked] = useState(false);
    const [emailAddr, setEmailAddr] = useState("");
    const [checkEmailNextInd, setCheckEmailNextInd] = useState(false);
    const [callEmailVerification, setCallEmailVerification] = useState(false);
    const [showCheckEmailModal, setShowCheckEmailModal] = useState(false);
    const hideTestButton = true;

    const { data: emailResponse, isFetched: isFetchedData } = useQuery({
        queryKey: [QUERY_KEYS.VERIFY_EMAIL_ADDRESS, emailAddr, accu],
        queryFn: () => callChallengeResetPasswordFreemium(emailAddr, accu),
        enabled: callEmailVerification && String(emailAddr).trim() !== ""
    });

    const updateAngularRoutingState = useCallback(() => {
        /**
         * TODO: Delete this function when Angular is removed. Since we are using the React router
         * we need to update the Angular routing state to match the new React routing state.
         */
        if (location && scope) {
            location.path(LOGIN_HELP_LETS_TRY_ELSE);
            scope.$apply();
        }
    }, [location, scope]);

    useEffect(() => {
        setFocus("email");
    }, [setFocus]);

    const onSubmit = useCallback(
        async (formData) => {
            const { email } = formData;

            setEmailAddr(email);
            setContinueClicked(true);
            setCallEmailVerification(true);

            const payload = String(button?.continue).toLowerCase();
            const formType: (typeof AMPLITUDE_EVENTS)[keyof typeof AMPLITUDE_EVENTS] =
                AMPLITUDE_EVENTS.SELECT_BUTTON;

            eventBus.dispatch(formType, {}, payload);

            dispatchAmplitude({
                eventType: formType,
                selection: payload,
                payload: {
                    payload
                }
            });
        },
        [button?.continue]
    );

    const handleInvalid = useCallback(() => {
        control.setError("root", {
            message: emailFormat
        });
    }, [control, emailFormat]);

    const triggerValidation = (e) => {
        trigger(e.target.name);
    };

    const handleOnBlur = (e) => {
        dispatchAmplitudeEvent(e);
    };

    /**
     * Dispatches an amplitude event when a user interacts with the form input textbox.
     */
    // eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const dispatchAmplitudeEvent = useCallback((event: MouseEvent) => {
        const payload = String(event.currentTarget.id).toLowerCase();
        const formType: (typeof AMPLITUDE_EVENTS)[keyof typeof AMPLITUDE_EVENTS] =
            AMPLITUDE_EVENTS.SELECT_FIELD;

        eventBus.dispatch(formType, event.target, payload);

        dispatchAmplitude({
            eventType: formType,
            selection: payload,
            payload: {
                payload
            }
        });
    }, []);

    if (continueClicked && emailAddr > "" && isFetchedData) {
        setContinueClicked(false);

        if (emailResponse) {
            if (emailResponse?.spHeader?.status === "ACTIVE") {
                setCheckEmailNextInd(true);
                setShowCheckEmailModal(true);
            } else if (continueClicked) {
                navigate("/" + LOGIN_HELP_LETS_TRY_ELSE, {
                    replace: true,
                    state: { type: flowType }
                });
                updateAngularRoutingState();
            }
        } else {
            navigate("/" + LOGIN_HELP_LETS_TRY_ELSE, {
                replace: true,
                state: { type: flowType }
            });
            updateAngularRoutingState();
        }
    }

    const handleClose = (e) => {
        setShowCheckEmailModal(false);

        const payload = e.currentTarget.id === "hdrCloseBtn" ? "exist" : "close";
        const formType: (typeof AMPLITUDE_EVENTS)[keyof typeof AMPLITUDE_EVENTS] =
            AMPLITUDE_EVENTS.SELECT_BUTTON;

        eventBus.dispatch(formType, e.target, payload);

        dispatchAmplitude({
            eventType: formType,
            selection: payload,
            payload: {
                payload
            }
        });
    };

    const handleTestBtn = () => {
        navigate("/" + LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS, {
            replace: true,
            state: { type: flowType }
        });
        updateAngularRoutingState();
    };

    return (
        <>
            <form
                autoComplete="off"
                noValidate
                name="noPasswordForm"
                data-testid="loginhelp-no-password"
                className="loginhelp-container"
                onSubmit={handleSubmit(onSubmit, handleInvalid)}
            >
                {isSubmitting && (
                    <div className="loaderBackground">
                        <div className="loader"></div>
                    </div>
                )}

                <h1>{forgotPasswordTitle}</h1>
                <div className="description">{emailLinkToResetPW}</div>
                <b className={accu !== "MYERIRA" ? "description bold" : ""}>{emailAddrTitle}</b>

                <div className={accu !== "MYERIRA" ? "row-item-no-password" : ""} key={emailId}>
                    <div
                        className={`form-group  ${errors.email && "has-error"}`}
                        data-testid={`controlled-form-field-${emailId}`}
                    >
                        <label className="control-label" htmlFor={emailId}>
                            {""}
                        </label>
                        <input
                            className="text-container form-control"
                            data-testid={`controlled-form-field-input-${emailId}`}
                            id={emailId}
                            aria-label={emailId}
                            type={"text"}
                            {...register("email", {
                                required: emailRequired,
                                maxLength: {
                                    value: 80,
                                    message: emailFormat
                                },
                                pattern: {
                                    value: pattern,
                                    message: emailFormat
                                },
                                onChange: triggerValidation,
                                onBlur: handleOnBlur
                            })}
                        />

                        {errors.email && errors?.email?.message && (
                            <p className="error-block" aria-live="polite">
                                {String(errors.email.message)}
                            </p>
                        )}
                    </div>
                </div>

                <div className="button-container">
                    <button
                        id="continue"
                        className="btn btn-primary"
                        type="submit"
                        aria-label={button.continue}
                        data-selection={LoginHelpEvents.CTA_BUTTON}
                        disabled={!isValid || checkEmailNextInd}
                    >
                        {button.continue}
                    </button>
                </div>
                <br />
                {!hideTestButton && (
                    <div className="button-container">
                        <button
                            id="continue"
                            className="btn btn-primary"
                            type="button"
                            aria-label={button.continue}
                            data-selection={LoginHelpEvents.CTA_BUTTON}
                            onClick={handleTestBtn}
                        >
                            {"Test button for login help mfa"}
                        </button>
                    </div>
                )}
            </form>

            {checkEmailNextInd && (
                <Modal dialogClassName="check-email-modal" show={showCheckEmailModal}>
                    <div data-testid="check-email-modal">
                        <>
                            <Modal.Header className="modal-title" data-testid="check-email-content">
                                {checkEmailNext}
                                <button
                                    className="close"
                                    id="hdrCloseBtn"
                                    aria-label={button.close}
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={handleClose}
                                >
                                    X
                                </button>
                            </Modal.Header>
                        </>
                        <Modal.Body className="modal-body" id="modal-body">
                            {sentEmailLink}
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button
                                className="cancel-btn btn btn-primary"
                                type="button"
                                id="btnClose"
                                aria-label={button.close}
                                onClick={handleClose}
                            >
                                {button.close}
                            </button>
                        </Modal.Footer>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default NoPassword;
