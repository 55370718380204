import beneficiaryTemplate from "./summary/beneficiaryTemplate";
import planDetailTemplate from "./summary/planDetailTemplate";

const registrationSummaryTemplate = `
<div class="confirmation container">
    <div class="col-md-10 col-md-offset-1">

        <header class = "contained-form-header">
            <h1 class="header-title">
                {{vm.summary.firstName}} {{vm.summary.lastName}} 
            </h1>
            <p>{{ "summary.headline" | translate }}</p>
        </header>

        <div id="beneficiaryConfirmation" class="inner-container with-shadow with-padding clearfix">

            <div id="reg_summary-spinner" class="loaderBackground just-element" ng-if="!vm.isDataLoaded ">
                <div class="loader"></div>
            </div>
   
            ${planDetailTemplate}
            ${beneficiaryTemplate} 
            <pwr-e-delivery-inline-view-container ng-if="vm.allowEDelivery" action="vm.newSubmitEDeliveryAndNavigate" e-delivery-init-data="vm.eDeliveryInitData"></pwr-e-delivery-inline-view-container>

            <hr class="dashed" />
            <div class="row">
                <div class="col-md-3 col-md-offset-9">
                    <button class="btn btn-primary pull-right"
                            ng-click="vm.continueToMyAccount()"
                            ng-if="!vm.allowEDelivery">
                            {{ "button.agreeContinue" | translate }}
                    </button>
                    <pw-loading-button loading-button-class="btn btn-primary pull-right text-nowrap"
                                       default-text="{{ 'button.agreeContinue' | translate }}"
                                       action="vm.submitEDelivery($event)"
                                       ng-if="vm.allowEDelivery"
                                       id="registration-summary-continue">
                    </pw-loading-button>
                </div>
            </div>
        </div>

    </div>
</div>
`;
export default registrationSummaryTemplate;
