/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, createRef } from "react";

import DateUtil from "core-ui/client/src/app/DateUtil";

import { useForm } from "../../hooks/useForm";
import TranslationService from "../../services/translationService";

import { formFields, formValidations } from "./paoConstants";
import Input, { Select } from "./paoFields";

const IraPreAccountOpenning = (props) => {
    const [translations, setTranslations] = useState(null);
    const [countryCodes, setCountryCodes] = useState(null);
    const [stateCodes, setStateCodes] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const fieldsRef = useRef(Object.keys(formFields).map(() => createRef()));

    const {
        handleSubmit,
        data,
        errors,
        handleValidation,
        handleChange,
        handleFocus,
        handleKeyDown
    } = useForm({ formFields, formValidations });

    useEffect(() => {
        TranslationService.getTranslations((resp) => {
            setTranslations(resp);
        });
    }, []);

    /* eslint-disable react-hooks-extra/no-direct-set-state-in-use-effect */
    useEffect(() => {
        setCountryCodes(props.countrycodes);
        setStateCodes(props.statecodes);
        setIsLoading(props.loading);
        setServiceError(props.serviceError);
        if (props.loaded) {
            setLoaded(true);
        }
        if (props.isregistered !== null) {
            if (props.isregistered) {
                props.openExistingRetailMessageModal();
            } else {
                props.redirect();
            }
        }
    }, [props]);
    /* eslint-disable react-hooks-extra/no-direct-set-state-in-use-effect */

    const onSubmit = (e) => {
        e.preventDefault();
        const canSubmit = handleSubmit(e);
        if (canSubmit) {
            const date = DateUtil.getDateFormatted("MM/DD/YYYY", data.dateOfBirth);
            const new_date = DateUtil.getDateFormatted("YYYY-MM-DD", date);

            const formData = {
                ssn: data.ssnMasked.replaceAll("-", ""),
                firstName: data.name,
                middleName: "",
                lastName: data.lastname,
                suffix: data.suffix,
                birthdate: new_date,
                phoneNumber: data.phone,
                phoneNumberType: "",
                emailAddress: data.email,
                emailType: "",
                firstLineMailing: data.address,
                secondLineMailing: data.address2,
                city: data.city,
                stateCode: data.state,
                zipCode: data.zip,
                countryCode: data.country,
                languageCode: "",
                gender: ""
            };
            props.createRetailParticipant(formData);
        }
    };

    const tpl_loading = () => (
        <div className="loaderBackground pao">
            <div className="loader"></div>
        </div>
    );

    return translations && loaded ? (
        serviceError ? (
            <div>
                <header className="contained-form-header margin-top-default">
                    <h1>{translations.iraPreAccount.serviceError.title}</h1>
                </header>
                <div className="inner-container with-padding with-shadow ira-pre-account-form">
                    <p>{translations.iraPreAccount.serviceError.description}</p>
                </div>
            </div>
        ) : (
            <div aria-labelledby="title" aria-describedby="description">
                <h1 id="title">{translations.iraPreAccount.title}</h1>
                <p id="description">{translations.iraPreAccount.description}</p>
                <div className="inner-container with-padding with-shadow ira-pre-account-form">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <Input
                                name="name"
                                label={translations.iraPreAccount.firstName}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[0]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="lastname"
                                label={translations.iraPreAccount.lastName}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[1]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="suffix"
                                label={translations.iraPreAccount.suffix}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[2]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="ssn"
                                label={translations.individual.ssn}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-6"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                handleFocus={handleFocus}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[3]}
                            />
                            <Input
                                name="dateOfBirth"
                                label={translations.individual.dateOfBirth}
                                legend="MM/DD/YYYY"
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-6"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[5]}
                            />
                        </div>
                        <div className="row">
                            <Select
                                name="country"
                                label={translations.iraPreAccount.country}
                                options={countryCodes}
                                size="col-xs-12"
                                errors={errors}
                                handleChange={handleChange}
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="address"
                                label={translations.mailingAddress.addressLine1}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[7]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="address2"
                                label={translations.mailingAddress.addressLine2}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[8]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="city"
                                label={translations.mailingAddress.city}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                onKeyDown={handleKeyDown}
                                data={data}
                                ref={fieldsRef.current[9]}
                            />
                        </div>
                        <div className="row">
                            <Select
                                name="state"
                                label={translations.iraPreAccount.state}
                                options={stateCodes}
                                size="col-xs-6"
                                errors={errors}
                                handleChange={handleChange}
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[10]}
                            />
                            <Input
                                name="zip"
                                label={translations.iraPreAccount.postalCode}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-6"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[11]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="phone"
                                label={translations.phone_number_only}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="text"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[12]}
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="email"
                                label={translations.iraPreAccount.email}
                                errors={errors}
                                handleChange={(e, r) => handleChange(e, r)}
                                size="col-xs-12"
                                type="email"
                                handleValidation={(e, v) => handleValidation(e, v)}
                                msg={translations.iraPreAccount.errors}
                                validations={formValidations}
                                data={data}
                                onKeyDown={handleKeyDown}
                                ref={fieldsRef.current[13]}
                            />
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <button type="submit" className="btn btn-primary continue">
                                    {translations.button.continue}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {isLoading && tpl_loading()}
            </div>
        )
    ) : (
        tpl_loading()
    );
};

export default IraPreAccountOpenning;
