import ReferenceData from "../../constants/ReferenceData";
import { executePostWithPcapAuth } from "../../utils/appUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

interface AccountsMetaData {
    hasAggregated: boolean;
    hasCash: boolean;
    hasCredit: boolean;
    hasInvestment: boolean;
    hasOnUs: boolean;
}
interface ErrorData {
    code: string;
    message: string;
}
export interface AuthData {
    spData: {
        failed: number;
        pullResultCode: number;
        pullResultDesc: string;
        pullStatus: boolean;
    };
    spHeader: {
        SP_HEADER_VERSION: number;
        accountsMetaData: string[];
        accountsSummary: AccountsMetaData;
        authLevel: string;
        betaTester: boolean;
        developer: boolean;
        errors?: ErrorData[]; // Define the errors property as an array of ErrorData
        isDelegate: boolean;
        personId: number;
        qualifiedLead: boolean;
        status: string;
        success: boolean;
        userGuid: string;
        userStage: string;
        username: string;
    };
}
interface AuthenticateArgs {
    csrfToken: string;
}
export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain];
const authenticatePhone = async ({ csrfToken }: AuthenticateArgs): Promise<AuthData> => {
    const payload = {
        challengeReason: "DEVICE_AUTH",
        challengeMethod: "OP",
        apiClient: "WEB",
        bindDevice: false,
        csrf: csrfToken
    };

    const liveUrl = currentPcBaseApiUrl + ReferenceData.CALL_AUTHENTICATE_PHONE_AUTH;
    const result = await executePostWithPcapAuth(
        liveUrl,
        "callAuthenticatePhoneAuthFault",
        payload
    );
    return result.data;
};

export default authenticatePhone;
