import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import EnterpriseChatContainer from "./EnterpriseChatContainer";
const queryClient = new QueryClient();

const EnterpriseChatQueryWrapper = () => (
    <QueryClientProvider client={queryClient}>
        <EnterpriseChatContainer />
    </QueryClientProvider>
);

export default EnterpriseChatQueryWrapper;
